import Customisation from "../onboarding/Customisation";
import useSWR from "swr";
import {endpoints, fetcher} from "../../utils/axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {paths} from "../../routes/paths";
import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import Card from "@mui/material/Card";
import {useLocales} from "../../locales";
import {useSettingsContext} from "../../components/settings";

export default function CustomisationView() {
	const settings = useSettingsContext();
	const {t} = useLocales();
	const {data: configurationData, isLoading} = useSWR(endpoints.configurations.get, fetcher);

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<Container maxWidth={settings.themeStretch ? false : 'lg'}>
			<CustomBreadcrumbs
				heading={t('navigations.dashboard.settings.customisation')}
				links={[
					{
						name: t('breadcrumbs.dashboard'),
						href: paths.dashboard.root,
					},
					{
						name: t('navigations.dashboard.settings.title'),
					},
					{name: t('navigations.dashboard.settings.customisation')},
				]}
				sx={{
					mb: {xs: 3, md: 5},
				}}
			/>
			<Grid container spacing={3}>
				<Grid xs={12} md={12}>
					<Card sx={{p: 3}}>
						<Customisation configurationData={configurationData}/>
					</Card>
				</Grid>
			</Grid>
		</Container>

	)
}
