import React from 'react';
import Stack from "@mui/material/Stack";
import FormProvider, {RHFEditor, RHFTextField} from "../../../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {enqueueSnackbar} from "notistack";
import EmailTemplatePreview from "./EmailTemplatePreview";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import {useLocales} from "../../../../locales";
import Box from "@mui/material/Box";

const variables = [
	{ label: 'variables.customerName', value: "{{customer.name}}" },
	{ label: 'variables.customerEmail', value: "{{customer.email}}" },
	{ label: 'variables.customerPhoneNumber', value: "{{customer.phoneNumber}}" },
	{ label: 'variables.employeeName', value: "{{employee.name}}" },
	{ label: 'variables.employeeEmail', value: "{{employee.email}}" },
	{ label: 'variables.employeePhoneNumber', value: "{{employee.phoneNumber}}" },
	{ label: 'variables.appointmentDate', value: "{{appointment.date}}" },
	{ label: 'variables.appointmentTime', value: "{{appointment.time}}" },
	{ label: 'variables.serviceName', value: "{{service.name}}" },
	{ label: 'variables.serviceDuration', value: "{{service.duration}}" },
	{ label: 'variables.servicePrice', value: "{{service.price}}" },
	{ label: 'variables.serviceCurrency', value: "{{service.currency}}" }
];

const AppointmentTemplate = ({
	                             handleUpdate,
	                             defaultValues,
                             }) => {
	const { t } = useLocales();
	const Schema = Yup.object().shape({
		subject: Yup.string().required().max(70),
		title: Yup.string().required().max(50),
		body: Yup.string().required().max(1000),
		closing: Yup.string().required().max(1000),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues
	});

	const {
		watch,
		handleSubmit,
	} = methods;
	const values = watch();

	const onSubmit = handleSubmit(async (data) => handleUpdate(data), (errors) => {
		console.error(errors);
	});

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Stack spacing={3}>
				<Box>
					<Typography variant="h5" sx={{m: 0, p:0}}>{t('titles.variables')}</Typography>
					<Typography variant="caption" sx={{pb: 0}}>{t('subtitles.variables')}</Typography>
				</Box>
				<Grid container spacing={1} sx={{mb: 2, pt: 0}}>
					{variables.map((variable) => (
						<Grid item>
							<Chip label={t(variable.label)} key={variable.value} onClick={() => {
								navigator.clipboard.writeText(variable.value);
								enqueueSnackbar(t('snackbars.variableCopy', { label: t(variable.label) }), {variant: 'success'});
							}}/>
						</Grid>
					))}
				</Grid>
				<RHFTextField name="subject" label={t('fields.subject')} maxChars={70}/>
				<RHFTextField name="title" label={t('fields.title')} maxChars={50}/>

				<RHFEditor template simple name="body" label={t('fields.body')} id="body" maxChars={1000}/>
				<RHFEditor template simple name="closing" label={t('fields.closing')} maxChars={1000}/>
				<LoadingButton variant="contained" type="submit">{t('buttons.update')}</LoadingButton>
				<Divider>{t('titles.previewTemplate')}</Divider>
				<EmailTemplatePreview title={values?.title} body={values?.body} closing={values?.closing}/>
			</Stack>
		</FormProvider>
	);
};
AppointmentTemplate.propTypes = {
	defaultValues: PropTypes.object.isRequired,
	handleUpdate: PropTypes.func.isRequired,
};

export default AppointmentTemplate;
