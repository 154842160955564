import axios from 'axios';
// config
import {HOST_API} from 'src/config-global';
import {setSession} from "../auth/context/jwt/utils";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({baseURL: HOST_API});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = sessionStorage.getItem('accessToken');
		config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') || 'en';
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;

		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(res) => {
		const {accessToken} = res.data;

		if (accessToken) {
			setSession(accessToken);
		}
		return res;
	},
	(error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
	const [url, config] = Array.isArray(args) ? args : [args];
	const res = await axiosInstance.get(url, {...config});
	return res.data;
};

// ----------------------------------------------------------------------

const version = 'v1';

export const endpoints = {
	contact: `/${version}/contact`,
	subscriptions: {
		me: `/${version}/subscriptions/me`,
		quotas: `/${version}/subscriptions/quotas`,
		upgradePreview: (planId) => `/${version}/subscriptions/upgrade/${planId}/preview`,
		upgrade: (planId) => `/${version}/subscriptions/upgrade/${planId}`,
		paymentStatus: `/${version}/subscriptions/payments/status`,
		addNewPaymentMethod: `/${version}/subscriptions/payments/method`,
		paymentMethod: `/${version}/subscriptions/payments/method`,
		paymentMethodRemove: `/${version}/subscriptions/payments/method/remove`,
		paymentInvoices: `/${version}/subscriptions/payments/invoices`,
	},
	plans: {
		find: `/${version}/plan`,

	},
	templates: {
		get: (type, name) => `/${version}/templates/${type}/${name}`,
		update: (type, name) => `/${version}/templates/${type}/${name}`,
	},
	appointment: {
		cancellation: `/${version}/appointment/cancellation`,
	},
	customers: {
		find: `/${version}/customers`,
		create: `/${version}/customers`,
		patch: (id) => `/${version}/customers/${id}`,
		get: (id) => `/${version}/customers/${id}`,
		delete: (id) => `/${version}/customers/${id}`,
		deleteBulk: `/${version}/customers/remove/bulk`,
	},
	services: {
		find: `/${version}/services`,
		create: `/${version}/services`,
		patch: (id) => `/${version}/services/${id}`,
		get: (id) => `/${version}/services/${id}`,
		delete: (id) => `/${version}/services/${id}`,
		deleteBulk: `/${version}/services/remove/bulk`,
	},
	employees: {
		find: `/${version}/employees`,
		create: `/${version}/employees`,
		patch: (id) => `/${version}/employees/${id}`,
		get: (id) => `/${version}/employees/${id}`,
		delete: (id) => `/${version}/employees/${id}`,
		deleteBulk: `/${version}/employees/remove/bulk`,
	},
	configurations: {
		get: `/${version}/configurations`,
		find: `/${version}/configurations`,
		update: `/${version}/configurations`,
		logo: `/${version}/configurations/logo`,
	},
	clientsAttributes: `/${version}/client/attributes`,
	clientTableConfiguration: `/${version}/client/table-configuration`,
	chat: `/${version}/api/chat`,
	kanban: `/${version}/api/kanban`,
	calendar: {
		freeSlots: `/${version}/calendar/appointments/free-slots`,
		freeDays: `/${version}/calendar/appointments/free-days`,
		create: `/${version}/calendar/appointments`,
		find: `/${version}/calendar/appointments`,
		get: (id) => `/${version}/calendar/appointments/${id}`,
		getPopulateCustomers: (id) => `/${version}/calendar/appointments/${id}?populateCustomers=true`,
		patch: (id) => `/${version}/calendar/appointments/${id}`,
		patchCustomers: (id) => `/${version}/calendar/appointments/${id}/customers`,
		delete: (id) => `/${version}/calendar/appointments/${id}`,
		cancel: (id) => `/${version}/calendar/appointments/${id}/cancel`,
		logs: (id) => `/${version}/calendar/appointments/${id}/logs`,
	},
	auth: {
		me: `/${version}/auth/me`,
		login: `/${version}/auth/login`,
		register: `/${version}/auth/register`,
		activate: `/${version}/auth/activate`,
		resetPassword: `/${version}/auth/reset-password`,
		resetPasswordChange: `/${version}/auth/reset-password/change`,
		resendActivationCode: `/${version}/auth/regenerate-activation-code`,
	},
	mail: {
		list: `/${version}/mail/list`,
		details: `/${version}/mail/details`,
		labels: `/${version}/mail/labels`,
	},
	post: {
		list: `/${version}/post/list`,
		details: `/${version}/post/details`,
		latest: `/${version}/post/latest`,
		search: `/${version}/post/search`,
	},
	product: {
		list: `/${version}/product/list`,
		details: `/${version}/product/details`,
		search: `/${version}/product/search`,
	},
	organisation: {
		create: `/${version}/organisation`,
		me: `/${version}/organisation/me`,
		taxId: (id) => `/${version}/organisation/tax-id/${id}`,
		select: (id) => `/${version}/organisation/select/${id}`,
		update: (id) => `/${version}/organisation/${id}`,
		configuration: {
			update: `/${version}/organisation/configuration/settings`,
		}
	},
	user: {
		organisations: `/${version}/user/organisations`
	},
	invitations: {
		get: (id) => `/${version}/invitations/${id}`,
		accept: (id) => `/${version}/invitations/${id}/accept`,
	}
};
